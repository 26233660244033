/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
import 'classlist.js';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
import 'core-js/es6/reflect';


/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
import 'core-js/es7/reflect';


/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 **/
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

 // (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 // (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 // (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

 /*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
// (window as any).__Zone_enable_cross_context_check = true;

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */
declare global {
interface String {
   toDate(format: string): Date;
}}
String.prototype.toDate = function(format) {
 var normalized      = this.replace(/[^a-zA-Z0-9]/g, '-');
 var normalizedFormat= format.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');
 var formatItems     = normalizedFormat.split('-');
 var dateItems       = normalized.split('-');

 var monthIndex  = formatItems.indexOf("mm");
 var dayIndex    = formatItems.indexOf("dd");
 var yearIndex   = formatItems.indexOf("yyyy");
 var hourIndex     = formatItems.indexOf("hh");
 var minutesIndex  = formatItems.indexOf("ii");
 var secondsIndex  = formatItems.indexOf("ss");

 var today = new Date();

 var year  = yearIndex>-1  ? dateItems[yearIndex]    : today.getFullYear();
 var month = monthIndex>-1 ? dateItems[monthIndex]-1 : today.getMonth()-1;
 var day   = dayIndex>-1   ? dateItems[dayIndex]     : today.getDate();

 var hour    = hourIndex>-1      ? dateItems[hourIndex]    : today.getHours();
 var minute  = minutesIndex>-1   ? dateItems[minutesIndex] : today.getMinutes();
 var second  = secondsIndex>-1   ? dateItems[secondsIndex] : today.getSeconds();

 return new Date(year,month,day,hour,minute,second);
};
